import { Button } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { CountryPhoneInputValue } from 'antd-country-phone-input';
import { IBasicCard } from 'components/basic-card';
import PasswordLoginCard, {
  IPasswordLoginCard,
} from 'components/password-login-card';
import PasswordResetCard, {
  IPasswordResetCard,
} from 'components/password-reset-card';
import PhoneVerifyCard, {
  IPhoneVerifyCard,
} from 'components/phone-verify-card';
import PhoneLoginCard, { IPhoneLoginCard } from 'components/phone-login-card';

import styles from './index.module.less';

const MODE = {
  QUICK_LOGIN: 1,
  PASSWORD_LOGIN: 2,
  // REGISTER: 3,
  PHONE_VERIFY: 4,
  PASSWORD_RESET: 5,
};

export const Login = () => {
  const history = useHistory();
  const [isLearnInivationMode] = useState<boolean>(
    /%2Flearn%2Finvitation%3Fk%3D([a-z0-9]+)/.test(window.location.href),
  );
  const [countryPhone, _setCountryPhone] = useState<
    CountryPhoneInputValue | undefined
  >({
    short: 'CN',
  });
  const setCountryPhone = (value?: CountryPhoneInputValue) => {
    if (value?.phone) {
      _setCountryPhone(value);
    } else {
      _setCountryPhone({ short: 'CN' });
    }
  };
  const [mode, setMode] = useState(MODE.QUICK_LOGIN);
  const gotoPasswordLogin = (value?: CountryPhoneInputValue) => {
    setCountryPhone(value);
    setMode(MODE.PASSWORD_LOGIN);
  };
  const gotoPhoneLogin = () => setMode(MODE.QUICK_LOGIN);
  const gotoRegister = (value?: CountryPhoneInputValue) => {
    setCountryPhone(value);
    // setMode(MODE.REGISTER);
    history.push('/login/school/register?redirect=/learn');
  };
  const gotoPhoneVerify = (value?: CountryPhoneInputValue) => {
    setCountryPhone(value);
    setMode(MODE.PHONE_VERIFY);
  };
  const gotoPasswordReset = (value?: CountryPhoneInputValue) => {
    setCountryPhone(value);
    setMode(MODE.PASSWORD_RESET);
  };

  const ModeConfigMap: {
    [key: string]: [
      React.FC<Partial<IBasicCard>>,
      Partial<
        | IBasicCard
        | IPhoneLoginCard
        | IPasswordLoginCard
        | IPasswordResetCard
        | IPhoneVerifyCard
      >,
    ];
  } = {
    [MODE.QUICK_LOGIN]: [
      PhoneLoginCard,
      {
        countryPhone,
        onPasswordButtonClick: gotoPasswordLogin,
        isLearnInivationMode,
      },
    ],
    [MODE.PASSWORD_LOGIN]: [
      PasswordLoginCard,
      {
        countryPhone,
        onLinkButtonClick: gotoPhoneLogin,
        onRegisterClick: gotoRegister,
        onVerifyClick: gotoPhoneVerify,
        onPhoneLoginClick: gotoPhoneLogin,
        isLearnInivationMode,
      },
    ],
    [MODE.PHONE_VERIFY]: [
      PhoneVerifyCard,
      {
        countryPhone,
        onBack: gotoPasswordLogin,
        onRegisterClick: gotoRegister,
        onVerifySuccess: gotoPasswordReset,
        isLearnInivationMode,
      },
    ],
    [MODE.PASSWORD_RESET]: [
      PasswordResetCard,
      {
        countryPhone,
        onResetSuccess: gotoPasswordLogin,
        isLearnInivationMode,
      },
    ],
  };

  // ModeConfigMap[MODE.REGISTER] = ModeConfigMap[MODE.QUICK_LOGIN];

  const CurrentCard = ModeConfigMap[mode][0];
  const CurrentConfig = ModeConfigMap[mode][1];

  return (
    <div className={styles.bg}>
      <img
        className={styles['left-bottom']}
        src="https://staticcdn.boyuai.com/materials/2020/10/28/VkbOIqZCNLvLEGUubofYF.svg"
        alt="left-bottom-decoration"
      />
      <img
        className={styles['right-top']}
        src="https://staticcdn.boyuai.com/materials/2020/10/28/VkbOIqZCNLvLEGUubofYF.svg"
        alt="right-top-decoration"
      />

      <main className={styles.body}>
        <img
          className={styles.logo}
          src="https://staticcdn.boyuai.com/materials/2020/10/28/utXpIVRQsol_-Kg_KHWf8.png"
          alt="logo"
        />
        {}
        <CurrentCard {...CurrentConfig} />
      </main>

      <footer className={styles.footer}>
        注册即代表您同意{' '}
        <Button
          type="link"
          onClick={() => window.open('https://www.boyuai.com/page/terms')}
        >
          用户协议
        </Button>{' '}
        与{' '}
        <Button
          type="link"
          onClick={() => window.open('https://www.boyuai.com/page/privacy')}
        >
          隐私条款
        </Button>
      </footer>
    </div>
  );
};
