import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider as CountryPhoneInputProvider } from 'antd-country-phone-input';
import zh from 'world_countries_lists/data/zh/world.json';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'antd/dist/antd.css';
import 'antd-country-phone-input/dist/index.css';
import '@boyuai/captcha/dist/index.css';

import './index.less';

const history = createBrowserHistory();
if (process.env.REACT_APP_SENTRY === 'true') {
  Sentry.init({
    dsn:
      'https://c72ccf0bc1a84f58bb1a4291d4fdd051@o678810.ingest.sentry.io/5938778',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    release: process.env.REACT_APP_SENTRY_RELEASE || 'development',
    environment: process.env.REACT_APP_ENV || 'development',
    tracesSampleRate: 0.1,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

const flagStyle = { height: 16, verticalAlign: 'text-bottom' };

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <React.Fragment>
          <div>很抱歉，程序崩溃了，请刷新页面</div>
          <div>{error.toString()}</div>
          <div>{componentStack}</div>
        </React.Fragment>
      )}
    >
      <CountryPhoneInputProvider
        locale={zh}
        areaMapper={(rawArea) => {
          const area = {
            ...rawArea,
            emoji: (
              <img
                alt="flag"
                style={flagStyle}
                src={
                  require(`world_countries_lists/flags/24x24/${rawArea.short.toLowerCase()}.png`)
                    .default
                }
              />
            ),
          };
          if (area.name?.includes('香港') || area.name?.includes('澳门')) {
            return {
              ...area,
              name: `中国${area.name}`,
            };
          }
          if (area.name?.includes('台湾')) {
            return {
              ...area,
              name: '中国台湾',
              emoji: (
                <img
                  alt="flag"
                  style={flagStyle}
                  src={
                    require(`world_countries_lists/flags/24x24/cn.png`).default
                  }
                />
              ),
            };
          }
          return area;
        }}
      >
        <App history={history} />
      </CountryPhoneInputProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
