import * as Sentry from '@sentry/react';
import qs, { ParsedQs } from 'qs';

interface IRedirectProps {
  /**
   * 是否是新用户
   */
  isNew?: boolean;
}

/**
 * @example
 * /login => /
 * @example
 * /login?redirect=https%3A%2F%2Fdev.boyuai.com%2Flearn%2F => https://dev.boyuai.com/learn/
 * @example
 * /login?redirect=https%3A%2F%2Fdev.boyuai.com%2Flearn%2F%3Ftest%3D1 => https://dev.boyuai.com/learn/?test=1
 * @example
 * // 新注册用户带有身份参数
 * /login?redirect=https%3A%2F%2Fdev.boyuai.com%2Flearn%2F => https://dev.boyuai.com/learn/?isNew=true
 * @example
 * // 新注册用户且 redirectUrl 本身带有 query
 * /login?redirect=https%3A%2F%2Fdev.boyuai.com%2Flearn%2F%3Ftest%3D1 => https://dev.boyuai.com/learn/?test=1&isNew=true
 */
export const goBack = (props?: IRedirectProps) => {
  let redirectUrl: string | undefined;
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  // 优先从 query 读取
  if (query.redirect) {
    redirectUrl = query.redirect as string | undefined;
  }
  // 其次从 localStorage 读取跳转地址
  if (!redirectUrl) {
    redirectUrl = localStorage.getItem('login-redirect') as string | undefined;
  }
  // 返回首页作为兜底策略
  if (redirectUrl) {
    localStorage.removeItem('login-redirect');
  } else {
    redirectUrl = '/';
  }

  // 处理 redirectUrl 的 query
  const decodedRedirectUrl = decodeURIComponent(redirectUrl);
  let redirectQuery: ParsedQs = {};
  if (decodedRedirectUrl.includes('?')) {
    const [partialRedirectUrl, redirectQueryString] = decodedRedirectUrl.split(
      '?',
      2
    );
    redirectUrl = partialRedirectUrl;
    redirectQuery = qs.parse(redirectQueryString);
  }

  if (props?.isNew) {
    redirectQuery.isNew = 'true';
  }

  if (Object.getOwnPropertyNames(redirectQuery).length) {
    redirectUrl += `?${qs.stringify(redirectQuery)}`;
  }

  window.location.href = redirectUrl;
};

export const addSentryBreadcrumb = (
  message: string,
  data?: {
    [key: string]: any;
  }
) => {
  if (process.env.REACT_APP_SENTRY === 'true') {
    Sentry.addBreadcrumb({
      category: 'manual',
      message,
      data,
      level: Sentry.Severity.Info,
    });
    return;
  }

  // eslint-disable-next-line no-console
  console.log('SENTRY:', message, data);
};

export const captureMessageToSentry = (
  message: string,
  level: Sentry.Severity = Sentry.Severity.Info
) => {
  if (process.env.REACT_APP_SENTRY === 'true') {
    Sentry.captureMessage(message, level);
    return;
  }

  // eslint-disable-next-line no-console
  console.log('SENTRY:', message);
};

export const captureExceptionToSentry = (exception: any) => {
  if (process.env.REACT_APP_SENTRY === 'true') {
    Sentry.captureException(exception);
    return;
  }

  // eslint-disable-next-line no-console
  console.log('SENTRY:', exception);
};
