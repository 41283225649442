import { CountryPhoneInputValue } from 'antd-country-phone-input';
import { IBasicCard } from 'components/basic-card';
import PasswordLoginCard, {
  IPasswordLoginCard,
} from 'components/password-login-card';
import PasswordRegisterCard, {
  IPasswordRegisterCard,
} from 'components/password-register-card';
import PasswordResetCard, {
  IPasswordResetCard,
} from 'components/password-reset-card';
import PhoneVerifyCard, {
  IPhoneVerifyCard,
} from 'components/phone-verify-card';
import PhoneLoginCard, { IPhoneLoginCard } from 'components/phone-login-card';
import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import styles from './index.module.less';

const MODE = {
  QUICK_LOGIN: 1,
  PASSWORD_LOGIN: 2,
  REGISTER: 3,
  PHONE_VERIFY: 4,
  PASSWORD_RESET: 5,
};

export const SchoolLogin = () => {
  const history = useHistory();
  const match = useRouteMatch<{ mode: string }>();
  const isRegister = match.params.mode === 'register';
  const [mode, setMode] = useState(
    isRegister ? MODE.REGISTER : MODE.PASSWORD_LOGIN
  );
  useEffect(() => {
    setMode(isRegister ? MODE.REGISTER : MODE.PASSWORD_LOGIN);
  }, [isRegister]);

  const [countryPhone, _setCountryPhone] = useState<
    CountryPhoneInputValue | undefined
  >({
    short: 'CN',
  });
  const setCountryPhone = (value?: CountryPhoneInputValue) => {
    if (value?.phone) {
      _setCountryPhone(value);
    } else {
      _setCountryPhone({ short: 'CN' });
    }
  };
  const gotoPasswordLogin = (value?: CountryPhoneInputValue) => {
    setCountryPhone(value);
    setMode(MODE.PASSWORD_LOGIN);
    history.push('/login/school?redirect=/learn'); // 方便浏览器回退
  };
  const gotoPhoneLogin = () => setMode(MODE.QUICK_LOGIN);
  const gotoRegister = (value?: CountryPhoneInputValue) => {
    setCountryPhone(value);
    setMode(MODE.REGISTER);
    history.push('/login/school/register?redirect=/learn'); // 方便浏览器回退
  };
  const gotoPhoneVerify = (value?: CountryPhoneInputValue) => {
    setCountryPhone(value);
    setMode(MODE.PHONE_VERIFY);
  };
  const gotoPasswordReset = (value?: CountryPhoneInputValue) => {
    setCountryPhone(value);
    setMode(MODE.PASSWORD_RESET);
  };

  const ModeConfigMap: {
    [key: string]: [
      React.FC<Partial<IBasicCard>>,
      Partial<
        | IBasicCard
        | IPhoneLoginCard
        | IPasswordLoginCard
        | IPasswordRegisterCard
        | IPasswordResetCard
        | IPhoneVerifyCard
      >
    ];
  } = {
    [MODE.QUICK_LOGIN]: [
      PhoneLoginCard,
      {
        countryPhone,
        linkButtonTitle: '账号登录',
        onPasswordButtonClick: gotoPasswordLogin,
      },
    ],
    [MODE.PASSWORD_LOGIN]: [
      PasswordLoginCard,
      {
        countryPhone,
        title: '账号登录',
        onLinkButtonClick: gotoPhoneLogin,
        onRegisterClick: gotoRegister,
        onPhoneLoginClick: gotoPhoneLogin,
        onVerifyClick: gotoPhoneVerify,
      },
    ],
    // 默认用户名注册
    [MODE.REGISTER]: [
      PasswordRegisterCard,
      {
        onLoginClick: gotoPasswordLogin,
      },
    ],
    [MODE.PHONE_VERIFY]: [
      PhoneVerifyCard,
      {
        countryPhone,
        onBack: gotoPasswordLogin,
        onRegisterClick: gotoRegister,
        onVerifySuccess: gotoPasswordReset,
      },
    ],
    [MODE.PASSWORD_RESET]: [
      PasswordResetCard,
      {
        countryPhone,
        onResetSuccess: gotoPasswordLogin,
      },
    ],
  };

  const CurrentCard = ModeConfigMap[mode][0];
  const CurrentConfig = ModeConfigMap[mode][1];

  return (
    <div className={styles.app}>
      <img
        alt="logo"
        className={styles.logo}
        src="https://staticcdn.boyuai.com/user-assets/6074/Nf6SqBHvgpvBX6LETCRqGE/logo.png"
      />
      <div className={styles['content-wrapper']}>
        <img
          alt="welcome"
          className={styles.welcome}
          src="https://staticcdn.boyuai.com/user-assets/6074/d5yARoyFvU8Vt4GDgvk8tn/首屏图.png!png"
        />
        <CurrentCard {...CurrentConfig} />
      </div>
      <footer className={styles.footer}>
        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://boxiaoyu.com/page/about"
          >
            关于博小鱼
          </a>{' '}
          | © 2018-
          {new Date().getFullYear()} boyuai.com 版权所有
          上海伯禹教育科技有限公司{' '}
        </p>
        <p>
          <a target="_blank" rel="noreferrer" href="https://beian.miit.gov.cn/">
            沪ICP备18044415号
          </a>
          &nbsp;|&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202011260"
          >
            沪公网安备31011202011260号
          </a>
          &nbsp;|&nbsp;
          <a target="_blank" rel="noreferrer" href="https://beian.miit.gov.cn/">
            沪B2-20200532
          </a>
        </p>
      </footer>
    </div>
  );
};
