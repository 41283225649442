import { CSSProperties, useEffect, useRef, useState } from 'react';
import { WechatOutlined } from '@ant-design/icons';
import { Divider, message } from 'antd';
import { remote } from '@boyuai/utils';
import { goBack } from 'utils';
import styles from './index.module.less';

interface IProps {
  style?: CSSProperties;
}

const SocialLoginPanel: React.FC<IProps> = (props) => {
  const tokenRef = useRef();
  const subwindowRef = useRef<Window | null>();
  const [queryTime, setQueryTime] = useState(0);
  const handleWechatLogin = () => {
    remote.$post('/auth/login/wx', {}).then((data) => {
      if (!data) {
        message.error('系统故障，暂时无法登录');
        return;
      }

      tokenRef.current = data.token;
      subwindowRef.current = window.open(
        data.url,
        '微信登录',
        'width=500,height=500,left=350,top=150'
      );
      setQueryTime(1);
    });

    // demo
    // subwindowRef.current = window.open(
    //   'https://www.bing.com',
    //   '微信登录',
    //   'width=600,height=600,left=200,top=200'
    // );
    // setQueryTime(1);
  };

  useEffect(() => {
    if (!queryTime) return;

    const stopQuerying = () => {
      setQueryTime(0);
      subwindowRef.current?.close();
    };

    setTimeout(() => {
      // 超时自动关闭
      if (queryTime === 100) {
        stopQuerying();
        return;
      }
      // demo
      // setQueryTime((t) => t + 1);
      // console.log('querying...');

      remote
        .$get(
          `/auth/login/wx/state?token=${tokenRef.current}`,
          {},
          { hideLoading: true }
        )
        .then((data) => {
          if (data === 'waiting') {
            setQueryTime((t) => t + 1);
            return;
          }
          stopQuerying();
          if (data === 'user-not-found') {
            message.info('您的账号尚未绑定微信，请先注册');
            return;
          }

          // 拿到一次后端就会立刻删掉，避免不小心触发查询然后拿到空的
          if (typeof data === 'string') {
            remote.login(data);
            goBack();
          } else {
            // eslint-disable-next-line no-console
            console.log('no token');
          }
        });
    }, 777);
  }, [queryTime]);

  return (
    <div className={styles.panel} style={props.style}>
      <Divider>社交账号登录</Divider>
      <WechatOutlined
        style={{ color: '#60BE38', fontSize: 24 }}
        onClick={handleWechatLogin}
      />
    </div>
  );
};

export default SocialLoginPanel;
