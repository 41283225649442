import { Button } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import CountryPhoneInput, {
  CountryPhoneInputValue,
} from 'antd-country-phone-input';
import { Captcha, CaptchaInput } from '@boyuai/captcha';
import { CaptchaAttributes } from '@boyuai/captcha/dist/components/captcha';
import { CaptchaInputAttributes } from '@boyuai/captcha/dist/components/captcha-input';
import { sendPhoneCode } from 'services';
import { remote } from '@boyuai/utils';
import BasicCard, { IBasicCard } from 'components/basic-card';
import { addSentryBreadcrumb, captureExceptionToSentry } from 'utils';

export interface IPhoneVerifyCard extends Partial<IBasicCard> {
  countryPhone?: CountryPhoneInputValue;
  onRegisterClick: (value?: CountryPhoneInputValue) => void;
  onVerifySuccess: (value?: CountryPhoneInputValue) => void;
}

const PhoneVerifyCard: React.FC<Partial<IPhoneVerifyCard>> = (props) => {
  const [error, setError] = useState<any>('');
  const [countryPhone, setCountryPhone] = useState<
    CountryPhoneInputValue | undefined
  >(props.countryPhone);
  const [phoneCode, setPhoneCode] = useState<string>();
  const [captchaReady, setReady] = useState(false);
  const captchaRef = useRef<CaptchaAttributes>(null);
  const countryPhoneRef = useRef<CountryPhoneInputValue>();
  const captchaInputRef = useRef<CaptchaInputAttributes>(null);
  const handleSend = () => {
    setError(' ');
    return sendPhoneCode(
      captchaRef,
      countryPhoneRef.current?.phone,
      countryPhoneRef.current?.code,
      true
    )
      .then(() => {
        setError('');
      })
      .catch((e) => {
        addSentryBreadcrumb('验证码发送失败', countryPhoneRef.current);
        if (!e?.response) {
          setError('很抱歉，系统出了点问题');
          captureExceptionToSentry(e);
          return;
        }
        addSentryBreadcrumb('请求响应', e.response.data);
        if (e.response.data?.statusCode === 422) {
          return Promise.reject(
            <>
              {e.response.data?.message}
              <Button
                type="link"
                style={{ fontSize: 12, marginLeft: 4 }}
                onClick={() => props.onRegisterClick?.(countryPhone)}
              >
                立即注册
              </Button>
            </>
          );
        }
        const msg = e.response.data?.message;
        if (!msg && !e.response.data?.captchaResult) {
          addSentryBreadcrumb('缺少错误信息');
          captureExceptionToSentry(e);
        }
        return Promise.reject(msg ?? '请验证身份');
      });
  };

  useEffect(() => {
    if (props.countryPhone) {
      countryPhoneRef.current = props.countryPhone;
    }
  }, [props.countryPhone]);

  const handleVerify = useCallback(() => {
    setError(' ');
    return remote
      .$post(
        '/auth/login/phone',
        {
          code: phoneCode,
          phone: countryPhone?.phone,
          countryCode: countryPhone?.code,
        },
        { throwException: true }
      )
      .then((data) => {
        setError('');
        remote.login(data.token);
        props.onVerifySuccess?.(countryPhone);
      })
      .catch((e) => {
        addSentryBreadcrumb('手机号验证失败', countryPhoneRef.current);
        if (!e?.response) {
          setError('很抱歉，系统出了点问题');
          captureExceptionToSentry(e);
          return;
        }
        addSentryBreadcrumb('请求响应', e.response.data);
        const msg = e.response.data?.message;
        if (!msg) {
          addSentryBreadcrumb('缺少错误信息');
          captureExceptionToSentry(e);
        }
        setError(msg ?? '验证失败');
      });
  }, [countryPhone, phoneCode, props]);

  return (
    <BasicCard
      title="忘记密码"
      onBack={props.onBack}
      content={
        <>
          <CountryPhoneInput
            placeholder="手机号"
            value={countryPhone}
            onChange={(value) => {
              setCountryPhone(value);
              countryPhoneRef.current = value;
            }}
            onPressEnter={() => {
              captchaInputRef.current?.send();
            }}
          />
          <CaptchaInput
            ref={captchaInputRef}
            placeholder="请输入验证码"
            countdown={60}
            storageKey={`${countryPhone?.phone}-sended-at`}
            btnProps={{
              style: { height: 22 },
              loading: !captchaReady,
              disabled:
                !countryPhone?.phone ||
                (countryPhone.code === 86 &&
                  countryPhone?.phone?.length !== 11) ||
                !countryPhone.code,
            }}
            value={phoneCode}
            onChange={(e) => setPhoneCode(e.target.value)}
            onSend={handleSend}
            onSendError={(err) => {
              setError(err);
            }}
          />
          <Captcha
            ref={captchaRef}
            onFinish={() => setReady(true)}
            onSuccess={() => captchaInputRef.current?.send()}
          />
        </>
      }
      error={error}
      primaryButtonText="下一步"
      primaryButtonDisabled={
        !countryPhone?.phone || !countryPhone.phone || phoneCode?.length !== 6
      }
      onPrimaryButtonClick={handleVerify}
      {...props}
    />
  );
};

export default PhoneVerifyCard;
