import { useEffect, useRef, useState } from 'react';
import { Button, Input } from 'antd';
import BasicCard, { IBasicCard } from 'components/basic-card';
import { remote } from '@boyuai/utils';
import { Captcha } from '@boyuai/captcha';
import { CaptchaAttributes } from '@boyuai/captcha/dist/components/captcha';
import { addSentryBreadcrumb, captureExceptionToSentry, goBack } from 'utils';
import { validateUsername, validatePassword } from '@boyuai/validator';

export interface IPasswordRegisterCard extends Partial<IBasicCard> {
  onLoginClick: () => void;
}

const PasswordRegisterCard: React.FC<Partial<IPasswordRegisterCard>> = (
  props
) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const captchaRef = useRef<CaptchaAttributes>(null);

  const usernameRef = useRef<string>('');
  const passwordRef = useRef<string>('');
  const confirmPasswordRef = useRef<string>('');
  const [captchaReady, setReady] = useState(false);
  const [error, setError] = useState<any>();
  const [tipRight, setTipRight] = useState<any>();

  useEffect(() => {
    if (!error) {
      setTipRight(null);
    }
  }, [error]);

  const validateUsernameAndPrompt = (input: string) => {
    const result = validateUsername(input);
    if (!result.passed) {
      setError(result.reasons[0]);
    } else {
      setError('');
    }
    return result.passed;
  };

  const validatePasswordAndPrompt = (input: string) => {
    const result = validatePassword(input);
    if (!result.passed) {
      setError(result.reasons[0]);
    } else {
      setError('');
    }
    return result.passed;
  };

  const validateConfirmPassword = (input: string) => {
    if (input === passwordRef.current) {
      setError('');
      return true;
    }
    setError('两次输入的密码不一致');
    return false;
  };

  const validateForm = () => {
    return (
      validateUsernameAndPrompt(usernameRef.current) &&
      validatePasswordAndPrompt(passwordRef.current) &&
      validateConfirmPassword(confirmPasswordRef.current)
    );
  };

  // 此函数会被 React 之外的代码调用，不支持 useState
  const register = () => {
    setError('');
    setTipRight(null);
    if (!validateForm()) {
      return Promise.resolve();
    }

    if (!captchaRef.current) {
      setError('captcha 加载失败');
      return Promise.resolve();
    }

    let payload = {
      username: usernameRef.current,
      password: passwordRef.current,
      captcha: captchaRef.current.getCaptcha(),
    };

    return remote
      .$post('/auth/register', payload, { throwException: true })
      .then((data) => {
        setError('');
        captchaRef.current!.handleNext();
        remote.login(data.token);
        goBack();
      })
      .catch((e) => {
        payload.password = '';
        addSentryBreadcrumb('密码注册失败', payload);
        if (!e?.response) {
          setError('很抱歉，系统出了点问题');
          captureExceptionToSentry(e);
          return;
        }
        addSentryBreadcrumb('请求响应', e.response.data);

        if (e.response.data?.captchaResult) {
          const { captchaResult } = e.response.data;
          captchaRef.current!.handleNext(captchaResult);
          if (captchaResult === 800 || captchaResult === 900) {
            setError('请勿频繁注册');
          } else {
            setError('请验证身份');
          }
          return;
        }
        const msg = e.response.data?.message;
        if (!msg) {
          addSentryBreadcrumb('缺少错误信息');
          captureExceptionToSentry(e);
        }
        // onBlur 以后校验又过了，导致这个按钮点不着，体验反而不好，先不加了
        // if (msg?.includes?.('已存在')) {
        //   setTipRight(loginBtn);
        // }
        setError(msg ?? '注册失败');
      });
  };

  const loginBtn = (
    <Button
      type="link"
      style={{
        fontSize: 12,
      }}
      onClick={() => {
        props.onLoginClick?.();
      }}
    >
      立即登录
    </Button>
  );

  return (
    <BasicCard
      title="欢迎注册"
      content={
        <>
          <Input
            placeholder="用户名"
            value={username}
            onBlur={() => validateUsernameAndPrompt(usernameRef.current)}
            onChange={(e) => {
              setUsername(e.target.value);
              usernameRef.current = e.target.value;
            }}
          />
          <Input
            placeholder="密码"
            type="password"
            value={password}
            onBlur={() =>
              validateUsernameAndPrompt(usernameRef.current) &&
              validatePasswordAndPrompt(passwordRef.current)
            }
            onChange={(e) => {
              setPassword(e.target.value);
              passwordRef.current = e.target.value;
            }}
          />
          <Input
            placeholder="重复密码"
            type="password"
            value={confirmPassword}
            onBlur={validateForm}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              confirmPasswordRef.current = e.target.value;
            }}
          />
          <Captcha
            ref={captchaRef}
            onFinish={() => setReady(true)}
            onSuccess={register}
          />
        </>
      }
      tip={<>已经有账号？{loginBtn}</>}
      tipRight={tipRight}
      error={error}
      primaryButtonText="注册"
      onPrimaryButtonClick={register}
      primaryButtonDisabled={
        !captchaReady || !username || !password || !confirmPassword
      }
      bottom={
        <div
          style={{
            color: 'rgba(0, 0, 0, 0.35)',
            textAlign: 'center',
            fontSize: 12,
            marginTop: 16,
          }}
        >
          注册即代表您同意{' '}
          <Button
            type="link"
            style={{ fontSize: 12 }}
            onClick={() => window.open('https://www.boyuai.com/page/terms')}
          >
            用户协议
          </Button>{' '}
          与{' '}
          <Button
            type="link"
            style={{ fontSize: 12 }}
            onClick={() => window.open('https://www.boyuai.com/page/privacy')}
          >
            隐私条款
          </Button>
        </div>
      }
      {...props}
    />
  );
};

export default PasswordRegisterCard;
