import { message } from 'antd';
import { RefObject } from 'react';
import { remote } from '@boyuai/utils';
import { CaptchaAttributes } from '@boyuai/captcha/dist/components/captcha';

export const sendPhoneCode = (
  captchaRef: RefObject<CaptchaAttributes>,
  phone?: string,
  countryCode?: number,
  noRegister?: boolean, // 非注册模式，未注册情况下会报错
) => {
  if (!captchaRef.current) {
    return Promise.reject('captcha 加载失败');
  }
  const captcha = captchaRef.current.getCaptcha();

  return remote
    .$post(
      '/auth/login/phone/code',
      {
        captcha,
        phone,
        countryCode,
        noRegister: noRegister === undefined ? true : noRegister,
      },
      { throwException: true },
    )
    .then((data) => {
      captchaRef.current!.handleNext();
      message.success('验证码已发送');
      return data;
    })
    .catch((e) => {
      if (e?.response?.data?.captchaResult) {
        captchaRef.current!.handleNext(e.response.data.captchaResult);
      }
      throw e;
    });
};

const QZ_API_ROOT =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://www.boyuai.com/api/qz'
    : 'https://dev.boyuai.com/api/qz';

export const setQzToken = (token: string) => {
  localStorage.setItem('qz-jwt', token);
};

export const sendQzPhoneCode = () => {
  return Promise.resolve();
};

export const qzLogin = ({
  countryCode,
  phone,
  verifyCode,
}: {
  countryCode: number;
  phone: string;
  verifyCode: number;
}) => {
  return remote.$post(
    QZ_API_ROOT + '/auth',
    {
      countryCode,
      phone,
      verifyCode,
    },
    { throwException: true },
  );
};
