import { remote } from '@boyuai/utils';
import { Input } from 'antd';
import { CountryPhoneInputValue } from 'antd-country-phone-input';
import { useCallback, useEffect, useRef, useState } from 'react';
import BasicCard, { IBasicCard } from 'components/basic-card';
import { addSentryBreadcrumb, captureExceptionToSentry } from 'utils';
import { validatePassword } from '@boyuai/validator';

export interface IPasswordResetCard extends Partial<IBasicCard> {
  countryPhone?: CountryPhoneInputValue;
  onResetSuccess: () => void;
}

const PasswordResetCard: React.FC<Partial<IPasswordResetCard>> = (props) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const countryPhoneRef = useRef<CountryPhoneInputValue>();

  useEffect(() => {
    if (props.countryPhone) {
      countryPhoneRef.current = props.countryPhone;
    }
  }, [props.countryPhone]);

  const { onResetSuccess } = props;
  const changePassword = useCallback(() => {
    setError(' ');
    return remote
      .$patch(
        '/user/password',
        { password: newPassword },
        { throwException: true }
      )
      .then(() => {
        setError('');
        onResetSuccess?.();
      })
      .catch((e) => {
        addSentryBreadcrumb('密码重置失败', countryPhoneRef.current);
        if (!e?.response) {
          setError('很抱歉，系统出了点问题');
          captureExceptionToSentry(e);
          return;
        }
        addSentryBreadcrumb('请求响应', e.response.data);
        const msg = e.response.data?.message;
        if (!msg) {
          addSentryBreadcrumb('缺少错误信息');
          captureExceptionToSentry(e);
        }
        setError(msg ?? '重置失败');
      });
  }, [newPassword, onResetSuccess]);

  const validatePasswordAndPrompt = (input: string) => {
    const result = validatePassword(input);
    if (!result.passed) {
      setError(result.reasons[0]);
    } else {
      setError('');
    }
    return result.passed;
  };

  const validateConfirmPassword = (input: string) => {
    if (input === newPassword) {
      setError('');
      return true;
    }
    setError('两次输入的密码不一致');
    return false;
  };

  return (
    <BasicCard
      title="设置新密码"
      content={
        <>
          <Input
            placeholder="新密码"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            onBlur={(e) => validatePasswordAndPrompt(e.target.value)}
          />
          <Input
            placeholder="重复密码"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onBlur={(e) =>
              validatePasswordAndPrompt(e.target.value) &&
              validateConfirmPassword(e.target.value)
            }
          />
        </>
      }
      error={error}
      primaryButtonText="重置密码"
      primaryButtonDisabled={!newPassword || !confirmPassword}
      onPrimaryButtonClick={async () => {
        // 调试安全验证
        // await remote.$post('/user/lock', {});
        return changePassword();
      }}
      {...props}
    />
  );
};

export default PasswordResetCard;
