import { BrowserRouter, Switch, Route, Router } from 'react-router-dom';
import { History } from 'history';
import { Login } from 'pages/login';
import { SchoolLogin } from 'pages/school-login';
import { SentryTest } from 'pages/sentry';
import { remote } from '@boyuai/utils';
import { goBack } from 'utils';
import { QzLogin } from 'pages/qz-login';

const App = ({ history }: { history: History }) => {
  // 已登录的情况下直接返回
  // 青舟登录暂时与波波鱼部署在一起，这里临时区分，后续分开部署
  if (
    process.env.NODE_ENV !== 'development' &&
    !window.location.href.includes('login/qz') &&
    remote.isLogin()
  ) {
    goBack();
    return null;
  }

  return (
    <Router history={history}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route path="/login/school/:mode">
            <SchoolLogin />
          </Route>
          <Route path="/login/school/">
            <SchoolLogin />
          </Route>
          <Route path="/login/qz">
            <QzLogin />
          </Route>
          <Route path="/login/sentry/">
            <SentryTest />
          </Route>
        </Switch>
      </BrowserRouter>
    </Router>
  );
};

export default App;
