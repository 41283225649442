import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import { throttle } from 'lodash';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styles from './index.module.less';

export interface IBasicCard {
  title: string;
  /** 提供 handler 会显示返回按钮 */
  onBack?: () => void;
  content: React.ReactNode;
  tip?: React.ReactNode | string;
  /** error 会覆盖 tip */
  error?: React.ReactNode | string;
  /** tip 右边的自定义区域 */
  tipRight?: React.ReactNode;
  primaryButtonText: string;
  primaryButtonDisabled?: boolean;
  onPrimaryButtonClick?: () => Promise<any>;
  linkButtonTitle?: string;
  onLinkButtonClick?: React.MouseEventHandler<HTMLElement>;
  helpButtonTitle?: string;
  onHelpButtonClick?: React.MouseEventHandler<HTMLElement>;
  /** 底部自定义区域 */
  bottom?: React.ReactNode;
  /** 波波鱼短链邀请课 */
  isLearnInivationMode?: Boolean;
  height?: string | number;
}

const BasicCard: React.FC<IBasicCard> = ({
  onPrimaryButtonClick,
  ...props
}) => {
  const primaryButtonRef = useRef(null);
  const [primaryButtonLoading, setPrimaryButtonLoading] = useState(false);
  const handlePrimaryButtonClick = useCallback(() => {
    if (!onPrimaryButtonClick) return;
    setPrimaryButtonLoading(true);
    onPrimaryButtonClick().finally(() => {
      setPrimaryButtonLoading(false);
    });
  }, [onPrimaryButtonClick]);

  const clickPrimaryButton = throttle(() => {
    (primaryButtonRef.current as any)?.click();
  }, 1000);
  const handleLastPressEnter = useCallback(
    (event: any) => {
      if (event.keyCode === 13) {
        clickPrimaryButton();
      }
    },
    [clickPrimaryButton],
  );
  const handlePressEnter = (event: any, nextElement: HTMLInputElement) => {
    if (event.keyCode === 13) {
      nextElement?.focus();
    }
  };
  // 监听 content 区域所有 antd input 的 press enter
  // press enter 最后一个时，触发主按钮逻辑，除此之外 focus 到下一个
  useEffect(() => {
    const contents: HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName(
      'ant-input',
    ) as HTMLCollectionOf<HTMLInputElement>;
    const contentEventMap: {
      [key: number]: EventListenerOrEventListenerObject;
    } = {};
    for (let i = 0; i < contents.length; i++) {
      if (i === contents.length - 1) {
        contents[i].addEventListener('keyup', handleLastPressEnter);
      } else {
        contentEventMap[i] = (e: any) => handlePressEnter(e, contents[i + 1]);
        contents[i].addEventListener('keyup', contentEventMap[i]);
      }
    }

    return () => {
      for (let i = 0; i < contents.length; i++) {
        if (i === contents.length - 1) {
          contents[i].removeEventListener('keyup', handleLastPressEnter);
        } else {
          contents[i].removeEventListener('keyup', contentEventMap[i]);
        }
      }
    };
  }, [handleLastPressEnter]);

  return (
    <div
      className={`login-basic-card ${styles['basic-card']}`}
      style={{ height: props.height || 420 }}
    >
      <div className={styles.header}>
        {!!props.onBack && (
          <ArrowLeftOutlined
            className={styles['back-button']}
            onClick={props.onBack}
          />
        )}
        {props.title}
      </div>
      <div id="card-content">{props.content}</div>
      <div className={props.error ? styles.error : styles.tip}>
        <div>{props.error ? props.error : props.tip}</div>
        {!!props.tipRight && props.tipRight}
      </div>
      <Button
        ref={primaryButtonRef}
        className={styles['primary-button']}
        type="primary"
        loading={primaryButtonLoading}
        disabled={props.primaryButtonDisabled || primaryButtonLoading}
        onClick={handlePrimaryButtonClick}
      >
        {props.primaryButtonText}
      </Button>
      {!!props.linkButtonTitle && (
        <div className={styles.buttons}>
          <div
            className={styles['help-button']}
            onClick={props.onHelpButtonClick}
          >
            {props.helpButtonTitle}
          </div>
          <Button
            className={styles['link-button']}
            type="link"
            onClick={props.onLinkButtonClick}
          >
            {props.linkButtonTitle}
          </Button>
        </div>
      )}
      <div>{props.bottom}</div>
    </div>
  );
};

export default BasicCard;
