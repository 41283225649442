import { useEffect, useRef, useState } from 'react';
import { Button, Input } from 'antd';
import CountryPhoneInput, {
  CountryPhoneInputValue,
} from 'antd-country-phone-input';
import BasicCard, { IBasicCard } from 'components/basic-card';
import SocialLoginPanel from 'components/social-login-panel';
import { remote } from '@boyuai/utils';
import { Captcha } from '@boyuai/captcha';
import { CaptchaAttributes } from '@boyuai/captcha/dist/components/captcha';
import { addSentryBreadcrumb, captureExceptionToSentry, goBack } from 'utils';

export interface IPasswordLoginCard extends Partial<IBasicCard> {
  countryPhone?: CountryPhoneInputValue;
  onPhoneLoginClick: (value?: CountryPhoneInputValue) => void;
  onRegisterClick: (value?: CountryPhoneInputValue) => void;
  onVerifyClick: (value?: CountryPhoneInputValue) => void;
}

const PasswordLoginCard: React.FC<Partial<IPasswordLoginCard>> = (props) => {
  const [overseaLogin, setOverseaLogin] = useState(false);
  const [account, setAccount] = useState(props.countryPhone?.phone);
  const [password, setPassword] = useState('');
  const [countryPhone, setCountryPhone] = useState<
    CountryPhoneInputValue | undefined
  >(props.countryPhone);
  const captchaRef = useRef<CaptchaAttributes>(null);
  const countryPhoneRef = useRef<CountryPhoneInputValue>();
  const accountRef = useRef<string>();
  const passwordRef = useRef<string>();
  // const [captchaReady, setReady] = useState(false);
  const [error, setError] = useState<any>();

  useEffect(() => {
    if (props.countryPhone) {
      countryPhoneRef.current = props.countryPhone;
      accountRef.current = props.countryPhone?.phone;
    }
  }, [props.countryPhone]);

  const register = (
    <Button
      type="link"
      style={{ fontSize: 12, marginLeft: 4 }}
      onClick={() => {
        if (overseaLogin) {
          props.onRegisterClick?.(countryPhoneRef.current);
          return;
        }

        props.onRegisterClick?.({
          phone: accountRef.current?.includes('@')
            ? undefined
            : accountRef.current,
          code: 86,
          short: 'CN',
        });
      }}
    >
      立即注册
    </Button>
  );

  const createResetBtn = (text: string, light: boolean = false) => (
    <Button
      type="link"
      style={{
        color: light ? undefined : 'rgba(0, 0, 0, 0.65)',
        fontSize: 12,
      }}
      onClick={() => {
        if (overseaLogin) {
          props.onVerifyClick?.(countryPhoneRef.current);
          return;
        }

        props.onVerifyClick?.({
          phone: accountRef.current?.includes('@')
            ? undefined
            : accountRef.current,
          code: 86,
          short: 'CN',
        });
      }}
    >
      {text}
    </Button>
  );
  const [tipRight, setTipRight] = useState(createResetBtn('忘记密码'));

  // 此函数会被 React 之外的代码调用，不支持 useState
  const login = () => {
    setError(' ');
    // if (!captchaRef.current) {
    //   setError('captcha 加载失败');
    //   return Promise.resolve();
    // }

    let payload = {
      username: accountRef.current,
      phone: accountRef.current,
      countryCode: 86,
      password: passwordRef.current,
      captcha: '',
    };
    if (captchaRef.current) {
      payload.captcha = captchaRef.current.getCaptcha();
    }
    if (overseaLogin) {
      payload.phone = countryPhoneRef.current!.phone as string;
      payload.countryCode = countryPhoneRef.current!.code as number;
    }

    let api = '/auth/login/password';
    let isUsername = false;
    if (accountRef.current) {
      // 用户名不能以数字开头
      isUsername = isNaN(Number.parseInt(accountRef.current.charAt(0)));
      if (isUsername) {
        api = '/auth/login/username';
      }
    }

    return remote
      .$post(api, payload, { throwException: true })
      .then((data) => {
        setError('');
        setTipRight(createResetBtn('忘记密码'));
        if (captchaRef.current) {
          captchaRef.current.handleNext();
        }
        remote.login(data.token);
        goBack();
      })
      .catch((e) => {
        delete payload.password;
        addSentryBreadcrumb('密码登录失败', payload);
        if (!e?.response) {
          setError('很抱歉，系统出了点问题');
          captureExceptionToSentry(e);
          return;
        }
        addSentryBreadcrumb('请求响应', e.response.data);

        setTipRight(<></>);
        if (e.response.data?.captchaResult) {
          const { captchaResult } = e.response.data;
          captchaRef.current!.handleNext(captchaResult);
          if (captchaResult === 800 || captchaResult === 900) {
            setError(
              <>
                为了你的账号安全，请使用{' '}
                <Button
                  type="link"
                  style={{
                    fontSize: 12,
                  }}
                  onClick={() => {
                    if (overseaLogin) {
                      props.onPhoneLoginClick?.(countryPhoneRef.current);
                      return;
                    }

                    props.onPhoneLoginClick?.({
                      phone: accountRef.current?.includes('@')
                        ? undefined
                        : accountRef.current,
                      code: 86,
                      short: 'CN',
                    });
                  }}
                >
                  短信验证登录
                </Button>
              </>
            );
          } else {
            setError('请验证身份');
          }
          return;
        }
        let button;
        let msg = e.response.data?.message;
        if (!msg) {
          addSentryBreadcrumb('缺少错误信息');
          captureExceptionToSentry(e);
        }
        if (msg?.includes?.('未注册')) {
          msg = isUsername ? '该用户名尚未注册' : '该手机号尚未注册';
          button = (
            <Button
              type="link"
              style={{ fontSize: 12, marginLeft: 4 }}
              onClick={() => props.onRegisterClick?.(countryPhone)}
            >
              立即注册
            </Button>
          );
        } else if (msg?.includes?.('不正确')) {
          msg = '账号或密码错误，请重新输入或';
          button = createResetBtn('重置密码', true);
        }
        setError(
          <>
            {msg ?? '登录失败'} {!!button && button}
          </>
        );
      });
  };

  return (
    <BasicCard
      title="密码登录"
      content={
        <>
          {overseaLogin ? (
            <CountryPhoneInput
              placeholder="手机号"
              value={countryPhone}
              onChange={(value) => {
                setCountryPhone(value);
                countryPhoneRef.current = value;
              }}
            />
          ) : (
            <Input
              placeholder="手机号 / 用户名"
              value={account}
              onChange={(e) => {
                setAccount(e.target.value);
                accountRef.current = e.target.value;
              }}
            />
          )}

          <Input
            placeholder="密码"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              passwordRef.current = e.target.value;
            }}
          />
          <Captcha
            ref={captchaRef}
            onFinish={() => {
              // setReady(true);
            }}
            onSuccess={login}
          />
        </>
      }
      tip={
        <>
          还没有账号？
          {register}
        </>
      }
      error={error}
      primaryButtonText="登录"
      onPrimaryButtonClick={login}
      primaryButtonDisabled={
        // !captchaReady ||
        (overseaLogin && (!countryPhone?.phone || !countryPhone.code)) ||
        (!overseaLogin && !account) ||
        !password
      }
      linkButtonTitle="快捷登录"
      helpButtonTitle={overseaLogin ? '手机号/用户名登录' : '海外手机号登录'}
      onHelpButtonClick={() => setOverseaLogin(!overseaLogin)}
      tipRight={tipRight}
      bottom={
        <SocialLoginPanel
          style={{
            position: 'absolute',
            bottom: 32,
            width: 'calc(100% - 64px)',
          }}
        />
      }
      {...props}
    />
  );
};

export default PasswordLoginCard;
