import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import CountryPhoneInput, {
  CountryPhoneInputValue,
} from 'antd-country-phone-input';
import { remote } from '@boyuai/utils';
import { Captcha, CaptchaInput } from '@boyuai/captcha';
import { CaptchaAttributes } from '@boyuai/captcha/dist/components/captcha';
import { CaptchaInputAttributes } from '@boyuai/captcha/dist/components/captcha-input';
import { addSentryBreadcrumb, captureExceptionToSentry, goBack } from 'utils';
import { qzLogin, sendPhoneCode, sendQzPhoneCode, setQzToken } from 'services';
import BasicCard, { IBasicCard } from 'components/basic-card';
import SocialLoginPanel from 'components/social-login-panel';

export interface IPhoneLoginCard extends Partial<IBasicCard> {
  countryPhone?: CountryPhoneInputValue;
  onPasswordButtonClick: (value?: CountryPhoneInputValue) => void;
  qzMode?: boolean;
}

const PhoneLoginCard: React.FC<Partial<IPhoneLoginCard>> = (props) => {
  const [tip, setTip] = useState('');
  const [error, setError] = useState('');
  const [countryPhone, setCountryPhone] = useState<
    CountryPhoneInputValue | undefined
  >(props.countryPhone);
  const [phoneCode, setPhoneCode] = useState<string>();
  const captchaInputRef = useRef<CaptchaInputAttributes>(null);
  const [captchaReady, setReady] = useState(false);
  const captchaRef = useRef<CaptchaAttributes>(null);
  const autoSendRef = useRef(false);
  // 由于 captcha 不是 react 组件，所以需要从 ref 里面读取
  const countryPhoneRef = useRef<CountryPhoneInputValue>();
  const handleSend = () => {
    setError(' ');
    if (props.qzMode) {
      return sendQzPhoneCode();
    }
    return sendPhoneCode(
      captchaRef,
      countryPhoneRef.current?.phone,
      countryPhoneRef.current?.code,
      false,
    )
      .then((data: any) => {
        setError('');
        if (data?.userExisted === false) {
          setTip('未注册的手机号验证后将自动创建伯禹账号');
        }
      })
      .catch((e) => {
        addSentryBreadcrumb('验证码发送失败', countryPhoneRef.current);
        if (!e?.response) {
          setError('很抱歉，系统出了点问题');
          captureExceptionToSentry(e);
          return;
        }
        addSentryBreadcrumb('请求响应', e.response.data);
        const msg = e.response.data?.message;
        if (!msg && !e.response.data?.captchaResult) {
          addSentryBreadcrumb('缺少错误信息');
          captureExceptionToSentry(e);
        }
        return Promise.reject(msg ?? '请验证身份');
      });
  };

  // 进入快捷登录卡片时，如果外部写入了数据，直接发送验证码
  useLayoutEffect(() => {
    if (autoSendRef.current) return;
    setTimeout(() => {
      if (countryPhone?.code && countryPhone.phone) {
        countryPhoneRef.current = countryPhone;
        captchaInputRef.current?.send();
      }
    }, 500);
    autoSendRef.current = true;
  }, [countryPhone]);

  const handleLogin = useCallback(async () => {
    setError(' ');
    if (props.qzMode) {
      return qzLogin({
        countryCode: countryPhone?.code!,
        phone: countryPhone?.phone!,
        verifyCode: Number(phoneCode),
      }).then((data) => {
        setError('');
        setQzToken(data.accessToken);
        goBack();
      });
    }
    return remote
      .$post(
        '/auth/login/phone',
        {
          code: phoneCode,
          phone: countryPhone?.phone,
          countryCode: countryPhone?.code,
        },
        { throwException: true },
      )
      .then((data) => {
        setError('');
        remote.login(data.token);
        goBack({ isNew: data.isNew });
      })
      .catch((e) => {
        addSentryBreadcrumb('手机号登录失败', countryPhoneRef.current);
        if (!e?.response) {
          setError('很抱歉，系统出了点问题');
          captureExceptionToSentry(e);
          return;
        }
        addSentryBreadcrumb('请求响应', e.response.data);
        const msg = e.response.data?.message;
        if (!msg) {
          addSentryBreadcrumb('缺少错误信息');
          captureExceptionToSentry(e);
        }
        setError(msg ?? '登录失败');
      });
  }, [countryPhone, phoneCode, props.qzMode]);

  return (
    <BasicCard
      title="快捷登录"
      content={
        <>
          <CountryPhoneInput
            placeholder="手机号"
            value={countryPhone}
            onChange={(value) => {
              setCountryPhone(value);
              countryPhoneRef.current = value;
            }}
            onPressEnter={() => {
              captchaInputRef.current?.send();
            }}
          />
          <CaptchaInput
            ref={captchaInputRef}
            placeholder="请输入验证码"
            countdown={60}
            storageKey={`${countryPhone?.phone}-sended-at`}
            btnProps={{
              style: { height: 22 },
              loading: !captchaReady,
              disabled:
                !countryPhone?.phone ||
                (countryPhone.code === 86 &&
                  countryPhone?.phone?.length !== 11) ||
                !countryPhone.code,
            }}
            value={phoneCode}
            onChange={(e) => setPhoneCode(e.target.value)}
            onSend={handleSend}
            onSendError={(err) => {
              setError(err);
            }}
          />
          <Captcha
            ref={captchaRef}
            onFinish={() => setReady(true)}
            onSuccess={() => captchaInputRef.current?.send()}
          />
        </>
      }
      tip={tip}
      error={error}
      primaryButtonText={props.isLearnInivationMode ? '领取课程' : '注册/登录'}
      primaryButtonDisabled={
        !countryPhone?.phone || !countryPhone.phone || phoneCode?.length !== 6
      }
      onPrimaryButtonClick={handleLogin}
      height={props.isLearnInivationMode ? 'auto' : 420}
      bottom={
        props.isLearnInivationMode ? null : (
          <SocialLoginPanel
            style={{
              position: 'absolute',
              bottom: 32,
              width: 'calc(100% - 64px)',
            }}
          />
        )
      }
      linkButtonTitle={props.isLearnInivationMode ? '' : '密码登录'}
      {...props}
      onLinkButtonClick={() => {
        props.onPasswordButtonClick?.(countryPhone);
      }}
    />
  );
};

export default PhoneLoginCard;
